import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { Knack } from "../types/Types";
import KnackData from "../data/KnackData.json";
import { useEffect, useState } from "react";

export interface KnackProps {
  knackType:
    | "Strength"
    | "Dexterity"
    | "Stamina"
    | "Charisma"
    | "Manipulation"
    | "Appearance"
    | "Perception"
    | "Intelligence"
    | "Wits";
}

const KnackView = (props: KnackProps) => {
  const knackData: Knack[] = KnackData.filter((x) => x.type === props.knackType) as unknown as Knack[];

  const [filteredKnacks, setFilteredKnacks] = useState(knackData);

  const [seeHero, setSeeHero] = useState(false);
  const [seeDemiGod, setSeeDemiGod] = useState(false);
  const [seeGod, setSeeGod] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setFilteredKnacks(knackData); }, [props.knackType]);

  useEffect(() => {
    let knacks: Knack[] = [...knackData]; 

    if(!seeHero && !seeDemiGod && !seeGod){
      setFilteredKnacks(knacks);
      return;
    }

    knacks = knacks.filter(knack => 
      (seeHero && knack.tier === 1) ||
      (seeDemiGod && knack.tier === 2) ||
      (seeGod && knack.tier === 3)
    );
    console.log(knacks);
    setFilteredKnacks(knacks);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeHero, seeDemiGod, seeGod]);

  return (
    <Box p={1} pl={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h2">
              {props?.knackType} - {knackData.length} knacks
            </Typography>
            <Box>
              <FormGroup>
                <FormControlLabel
                  label="Hero"
                  control={
                    <Checkbox
                      checked={seeHero}
                      onChange={(e, v) => {
                        setSeeHero(v);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="Demi-God"
                  control={
                    <Checkbox
                      checked={seeDemiGod}
                      onChange={(e, v) => {
                        setSeeDemiGod(v);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="God"
                  control={
                    <Checkbox
                      checked={seeGod}
                      onChange={(e, v) => {
                        setSeeGod(v);
                      }}
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>
        {filteredKnacks.map((x) => (
          <Grid item xs={12}>
            <Typography variant="h5">
              {x?.tier} - {x?.name}
            </Typography>
            <Typography variant="h6">Dice Pool: {x?.dicePool}</Typography>
            <Typography variant="h6">Cost: {x?.cost}</Typography>
            <Typography>{x?.description}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default KnackView;
