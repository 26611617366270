import { ArrowDownward } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Box,
  ListItemButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import PantheonData from "../data/PantheonData.json";

interface ObjectList {
  label: string,
  type: string
}

const AccordionList = () => {
  const navigate = useNavigate();

  const pantheonList: string[] = PantheonData.map((x) => x.name);

  const purviewList = [
    "Animal",
    "Chaos",
    "Darkness",
    "Death",
    "Dreams",
    "Earth",
    "Fertility",
    "Fire",
    "Forge",
    "Frost",
    "Guardian",
    "Health",
    "Illusion",
    "Justice",
    "Moon",
    "Psychopomp",
    "Sky",
    "Stars",
    "Sun",
    "War",
    "Water",
  ];

  const knackList = [
    "Strength",
    "Dexterity",
    "Stamina",
    "Charisma",
    "Manipulation",
    "Appearance",
    "Perception",
    "Intelligence",
    "Wits",
  ];

  const pantheonObjectList: ObjectList[] = pantheonList.map(name => ({
    label: name,
    type: 'Pantheons'
  }));

  const purviewObjectList: ObjectList[] = purviewList.map(name => ({
    label: name,
    type: 'Purviews'
  }));

  const knackObjectList: ObjectList[] = knackList.map(name => ({
    label: name,
    type: 'Knacks'
  }));

  const results = pantheonObjectList.concat(purviewObjectList).concat(knackObjectList);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Box p={1} sx={{ display: "flex" }}>
        <Autocomplete
          renderInput={(params) => <TextField {...params} label="Search" />}
          options={results}
          onChange={(x, y) => { if(y) navigate(`${y?.type.toLowerCase()}/${y?.label.toLowerCase()}`) }}
          sx={{width: '100%'}}
          groupBy={(option) => option.type}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ArrowDownward />}>
            <Typography>Pantheons</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense disablePadding>
              <>
                {pantheonList.map((x) => (
                  <ListItem disablePadding disableGutters>
                    <ListItemButton
                      onClick={() => {
                        navigate(`pantheons/${x.toLocaleLowerCase()}`);
                      }}
                    >
                      <ListItemText primary={x}></ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ArrowDownward />}>
            <Typography>Purviews</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense disablePadding>
              <>
                {purviewList.map((x) => (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`purviews/${x.toLocaleLowerCase()}`);
                      }}
                    >
                      <ListItemText primary={x}></ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ArrowDownward />}>
            <Typography>Knacks</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense disablePadding>
              <>
                {knackList.map((x) => (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`knacks/${x.toLocaleLowerCase()}`);
                      }}
                    >
                      <ListItemText primary={x}></ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default AccordionList;
