import "./App.css";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScionView from "./scion/views/ScionView";
import PurviewView from "./scion/views/PurviewView";
import KnackView from "./scion/views/KnackView";
import PantheonView from "./scion/views/PantheonView";
import PurviewData from "./scion/data/PurviewData.json";
import PantheonData from "./scion/data/PantheonData.json";
import { Purview } from "./scion/types/Types";
import Home from "./Home";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const purviewList: Purview[] = PurviewData;
  const pantheons: string[] = PantheonData.map((x) => x.name);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="scion" element={<ScionView />}>
              <Route path="pantheons">
                {pantheons.map((x) => (
                  <Route path={x.toLowerCase()} element={<PantheonView pantheonName={x} />} />
                ))}
              </Route>

              <Route path="purviews">
                {purviewList.map((x) => (
                  <Route path={x.name} element={<PurviewView purview={x} />} />
                ))}
              </Route>
              <Route path="knacks">
                <Route
                  path="strength"
                  element={<KnackView knackType={"Strength"} />}
                />
                <Route
                  path="dexterity"
                  element={<KnackView knackType={"Dexterity"} />}
                />
                <Route
                  path="stamina"
                  element={<KnackView knackType={"Stamina"} />}
                />
                <Route
                  path="charisma"
                  element={<KnackView knackType={"Charisma"} />}
                />
                <Route
                  path="manipulation"
                  element={<KnackView knackType={"Manipulation"} />}
                />
                <Route
                  path="appearance"
                  element={<KnackView knackType={"Appearance"} />}
                />
                <Route
                  path="perception"
                  element={<KnackView knackType={"Perception"} />}
                />
                <Route
                  path="intelligence"
                  element={<KnackView knackType={"Intelligence"} />}
                />
                <Route path="wits" element={<KnackView knackType={"Wits"} />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
