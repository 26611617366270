import { Box, Grid, Typography } from "@mui/material";
import { Purview } from "../types/Types";

export interface PurviewProps {
  purview: Purview;
}

const PurviewView = (props: PurviewProps) => {
  return (
    <Box p={1} pl={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">{props.purview.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{props.purview.summary}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <Typography fontWeight={2}>Associated With: </Typography>
            {props.purview.associations}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.purview.boons.map((x) => (
            <>
              <Typography variant="h5">{x.tier} - {x.name}</Typography>
              <Typography variant="h6">Dice Pool: {x.dicePool}</Typography>
              <Typography variant="h6">Cost: {x.cost}</Typography>
              <Typography>{x.description}</Typography>
            </>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PurviewView;
