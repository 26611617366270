import {
  Box,
  Drawer,
  Grid,
  Paper,
  SwipeableDrawer
} from "@mui/material";
import { Outlet } from "react-router";
import AccordionList from "../components/AccordionList";
import { useState } from "react";

//ToDo: Links for Gods, Links for Virtues
//ToDo: Maybe add Natures `

const ScionView = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <>
      <Box p={2}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={0} sm={3.5} md={2} lg={2}>
            <Box sx={{ display: "flex" }}>
              <SwipeableDrawer
                anchor="left"
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                open={mobileOpen}
                onClose={() => {
                  setMobileOpen(false);
                }}
                onOpen={() => {
                  setMobileOpen(true);
                }}
                variant="temporary"
                sx={{
                  display: { xs: "block", sm: "none" },
                }}
                disableSwipeToOpen={false}
                swipeAreaWidth={150}
              >
                {/* <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    visibility: "visible",
                    right: 0,
                    left: 5,
                  }}
                >
                  <Puller />
                </Box> */}
                <Paper elevation={1} sx={{ display: "flex", width: "200px" }}>
                  <AccordionList />
                </Paper>
              </SwipeableDrawer>
              <Drawer
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: 200,
                  },
                }}
                variant="permanent"
              >
                <Paper elevation={1} sx={{ display: "flex", width: "200px" }}>
                  <AccordionList />
                </Paper>
              </Drawer>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8.5} md={9} lg={10}>
            <Box sx={{ display: "flex" }}>
              <Paper sx={{ height: "95vh", width: "100vw", display: "flex" }}>
                <Box sx={{ overflow: "auto" }}>
                  <Outlet />
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ScionView;
