import { Button } from "@mui/material"
import { useEffect } from "react";
import { useNavigate } from "react-router"

const Home = () => {
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { navigate("scion"); }, [])

    return(
        <>
            <Button onClick={() => {navigate("scion")}}>
                Here
            </Button>
        </>
    )
}

export default Home;