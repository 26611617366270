import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import PantheonData from "../data/PantheonData.json";
import { Pantheon } from "../types/Types";

export interface PantheonProps {
  pantheonName: string;
}

const PantheonView = (props: PantheonProps) => {
  const pantheon: Pantheon = PantheonData.filter(
    (x) => x.name === props.pantheonName
  )[0] as unknown as Pantheon;

  return (
    <Box p={1} pl={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">{pantheon.name}</Typography>
          <Typography>{pantheon.summary}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Virtues</Typography>
          <List dense>
            {pantheon.virtues.map((x) => (
              <ListItem disablePadding>
                <ListItemText>{x}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">History</Typography>
          <Typography>{pantheon.history}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Nature</Typography>
          <Typography>{pantheon.nature}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Relationships</Typography>
          <Typography>{pantheon.relationships}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Members</Typography>
          <List dense>
            {pantheon.members.map((x) => (
              <ListItem disableGutters disablePadding>
                <ListItemText>
                  <Typography>
                    {x.name} - {x.description}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PantheonView;
